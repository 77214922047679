<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                MarketPlace Information
                            </h4>
                        </div>
                        <div class="row">
                            
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Brand Model:" label-for="brand_model_id">
                                    <b-form-select id="brand_model_id" placeholder="Select brand model" v-model="brand_model_id" :options="brandModels" required>
                                    </b-form-select>
                                </b-form-group>
                            </div>


                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Year:" label-for="year">
                                    <b-form-input id="year" placeholder="Enter year"
                                        v-model="year" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Brand Name:" label-for="brand_id">
                                    <b-form-select id="brand_id" placeholder="Select brand id" v-model="brand_id" :options="brands" required>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registration In:" label-for="registration_in">
                                    <b-form-input id="registration_in" placeholder="Enter registyration  in"
                                        v-model="registration_in" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registration Number:" label-for="registration_number">
                                    <b-form-input id="registration_number" placeholder="Enter registration  number"
                                        v-model="registration_number" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Engine Number:" label-for="engine_number">
                                    <b-form-input id="engine_number" placeholder="Enter engine nnumber"
                                        v-model="engine_number" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Color:" label-for="color">
                                    <b-form-input id="color" placeholder="Enter color"
                                        v-model="color" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="City:" label-for="city">
                                    <b-form-input id="city" placeholder="Enter city"
                                        v-model="city" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Area:" label-for="area">
                                    <b-form-input id="area" placeholder="Enter area"
                                        v-model="area" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Country:" label-for="country">
                                    <b-form-input id="country" placeholder="Enter country"
                                        v-model="country" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Mileage:" label-for="mileage">
                                    <b-form-input id="mileage" placeholder="Enter mileage"
                                        v-model="mileage" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Price:" label-for="price">
                                    <b-form-input id="price" placeholder="Enter price"
                                        v-model="price" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Kilometer Second Driven:" label-for="KMs_driven">
                                    <b-form-input id="KMs_driven" placeholder="Enter kilometer second"
                                        v-model="KMs_driven" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Fuel Type:" label-for="fuel_type">
                                    <b-form-input id="fuel_type" placeholder="Enter fuel type"
                                        v-model="fuel_type" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                            <b-form-group label="Transmission Type:" label-for="transmission_type">
                                <b-form-select id="transmission_type" v-model="transmission_type" :options="statusOptions" required>
                                </b-form-select>
                            </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Engine Capacity:" label-for="engine_capacity">
                                    <b-form-input id="engine_capacity" placeholder="Enter engine capacity"
                                        v-model="engine_capacity" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Description:" label-for="description">
                                    <b-form-input id="description" placeholder="Enter description"
                                        v-model="description" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Features:" label-for="features">
                                    <b-form-input id="features" placeholder="Enter features"
                                        v-model="features" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Assemble:" label-for="assembly">
                                    <b-form-input id="assembly" placeholder="Enter assembly"
                                        v-model="assembly" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Satus:" label-for="status">
                                    <b-form-input id="status" placeholder="Enter status"
                                        v-model="status" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <!-- ...............  -->
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Documents:" label-for="image">
                                <div style="margin-left: 3px; margin-bottom: 15px">
                                    <input type="file" accept="image/*" id="image" multiple @change="onProfilePictureChange" />
                                </div>
                            </b-form-group>
                        </div>
                        </div>

                        <!-- Bank Information End -->
                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            showModal: false,
            isLoading: false,
            // New data property for multiple images
            successMessage: "",
            errorMessage: "",
            year: "",
            brand_model_id: '',
            brandModels:[],
            brand_id: '',
            brands: [], 
            registration_in:'',
            registration_number: '',
            engine_number: '',
            color: '',
            city: '',
            area: '',
            country: '',
            mileage: '',
            price: '',
            KMs_driven: '',
            fuel_type: '',
            transmission_type: '',
            engine_capacity: '',
            description: '',
            features: '',
            assembly: '',
            status: '',
            // category:"",
            user_id:"",
            documents: [],
            image: [],
            statusOptions: [
            { value: null, text: 'Select Transmission Type' },
            { value: 'manual', text: 'Manual' },
            { value: 'auto', text: 'Auto' }
            ],
    
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },
    mounted() {
                axios.get('brand')
                    .then(response => {
                        this.brands = response.data.data.map(brand => ({
                            value: brand.id,  
                            text: brand.name  
                        }));
                        console.log(this.brands);
                    })
                    .catch(error => {
                        console.log(error);
                    });
                    axios.get('brandModel')
                        .then(response => {
    
                            this.brandModels = response.data.data.map(model => ({
                                value: model.id,  
                                text: model.title 
                            }));
                            console.log(this.brandModels);
                        })
                        .catch(error => {
                            console.log(error);
                        });
            },    
    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
      
      

        addUser() {
            this.isLoading = true;
            // Create a FormData object to handle the image file
            const formData = new FormData();
            formData.append("year", this.year);
            formData.append("brand_model_id", this.brand_model_id);
            formData.append("brand_id", this.brand_id);
            formData.append("registration_number", this.registration_number);
            formData.append("engine_number", this.engine_number);
            formData.append("registration_in", this.registration_in);
            formData.append("color", this.color);
            formData.append("city", this.city);
            formData.append("area", this.area);
            formData.append("country", this.country);
            formData.append("mileage", this.mileage);
            formData.append("price", this.price);
            formData.append("KMs_driven", this.KMs_driven);
            formData.append("fuel_type", this.fuel_type);
            formData.append("transmission_type", this.transmission_type);
            formData.append("engine_capacity", this.engine_capacity);
            formData.append("description", this.description);
            formData.append("features", this.features);
            formData.append("assembly", this.assembly);
            formData.append("status", this.status);
            formData.append("user_id", 1);
            // formData.append("category", this.category);
             // Document code here  
        //      if (this.documents && this.documents.length > 0) {
        //     this.documents.forEach((file, index) => {
        //         formData.append(`document[${index}]`, file);
        //     });
        // }
        if (this.image && this.image.length > 0) {
                this.image.forEach((file, index) => {
                    formData.append(`image[${index}]`, file);
                });
            }

            axios

                .post("marketPlaceStoreForNew", formData)
                .then((response) => {
                    console.log(response.data);
                    this.$bvToast.toast("Marketplace added successfully!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary", // Background color
                    });
                    this.isLoading = false;
                    // this.$router.push({ name: 'DriverList' });
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    console.log(error.response.data);
                    this.isLoading = false;
                });
        },
          
        //     onProfilePictureChange(event) {
        //     const file = event.target.files[0];
        //     if (file) {
        //         // Set the selected file to the data property
        //         this.documents = file;
        //     }
        // },
        onProfilePictureChange(event) {
                const files = event.target.files;

                if (files && files.length > 0) {
                    if (files.length > 10) {
                    // Show an error message if more than 3 images are selected
                    this.$bvToast.toast("You can only upload up to 3 images.", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary",
                    });

                    // Optionally, trim the selection to the first 3 images
                    this.image = Array.from(files).slice(0, 10);
                    } else {
                    // Store the selected images if 3 or fewer are selected
                    this.image = Array.from(files);
                    }
                }
                },
        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },

    onDocumentsChange(event) {
        const files = event.target.files;
        if (files && files.length > 0) {
            if (files.length > 3) {
                this.$bvToast.toast("You can only upload up to 3 documents.", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                    appendToast: true,
                    toaster: "b-toaster-top-right",
                    autoHideDelay: 5000,
                });

                this.documents = Array.from(files).slice(0, 3);
            } else {
                this.documents = Array.from(files);
            }
        }},
    },
};
</script>